<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Deposit Report</h1>
                    </div><!-- /.col --> 
                </div><!-- /.row --> 
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="end_date">Date</label>
                                            <input type="date" id="end_date" v-model="search.date" class="form-control" />
                                        </div> 
                                    </div>
                                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm ml-2" @click.prevent="reload" style="margin-top:28px;"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loading">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm text-center">
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Total Parcel</th>
                                                    <th>Price</th>
                                                    <th>Deposited</th>
                                                    <th>Ratio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td><span class="label p-1" :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>{{ item.total_parcel }}</td>
                                                    <td>{{ item.total_price }}</td>
                                                    <td>{{ item.total_collected }}</td>
                                                    <td>{{ item.ratio }} %</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr v-if="summary">
                                                    <th>Total</th>
                                                    <th>{{ summary.grandTotal }}</th>
                                                    <th>{{ summary.totalPrice }}</th>
                                                    <th>{{ summary.totalCollected }}</th>
                                                    <th>{{ summary.totalRatio }} %</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name: 'DepositReport',
        data () {
            return {              
                loading: false,             
                listData: [],
                summary: {
                    grandTotal: 0,
                    totalPrice: 0,
                    totalCollected: 0,
                    totalRatio: 0
                },
                search: {
                    date: moment().format('YYYY-MM-DD')
                }
            }
        },
        created(){
            this.loadData();
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){     
                this.loading = true
                const params = Object.assign(this.search, { rider_id: this.authUser.id })
                const response = await config.getData('/rider/dashboard/deposit-report', params)
                this.loading = false
                if (response.success){
                    this.listData = this.getRelationalData(response.data)
                } else {
                    this.listData = []
                } 
            },
            getRelationalData(data) {
                let totalParcel = 0;
                let totalPrice = 0;
                let totalCollected = 0;
                let totalRatio = 0;
                const grandTotal = data.reduce((acc, item) => acc + item.total_parcel, 0);

                const finalData = data.map(item => {
                    const tmpRatio = parseFloat((item.total_parcel / grandTotal) * 100);
                    totalParcel += parseInt(item.total_parcel) || 1; // Ensure total_price is treated as a number
                    totalPrice += parseFloat(item.total_price) || 0; // Ensure total_price is treated as a number
                    totalCollected += parseFloat(item.total_collected) || 0; // Ensure total_collected is treated as a number
                    totalRatio += tmpRatio;

                    return Object.assign(item, { grand_total: grandTotal, ratio: tmpRatio.toFixed(2) });
                });

                const summary = {
                    grandTotal: totalParcel,
                    totalPrice: totalPrice.toFixed(2),
                    totalCollected: totalCollected.toFixed(2),
                    totalRatio: totalRatio.toFixed(2)
                };

                Object.assign(this.summary, summary);

                return finalData;
            },
            reload () {
                this.commonStatus = []
                this.search = Object.assign({}, {
                    rider_id: this.authUser.id,
                    date: moment().format('YYYY-MM-DD')
                })
            },
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(tmp => tmp.id == status)
                return typeof tmpStatus !== 'undefined' ? tmpStatus.text : ''
            },
            gotToUrl (item) {
                if (item.status != 17 && item.status != 18) {
                    this.$router.push(`/order?date_parameter_id=4&start_date=${this.search.start_date}&end_date=${this.search.end_date}&query_status=${item.status}&collection=${item.collection}`)
                }                
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>

